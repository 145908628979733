import { primary } from '@phoenix/all';

export const TemplatesListStyles = `

  table {
    box-shadow: none !important;
  }

  thead {
    button {
      height: 16px;
      margin: 0 4px;
      border: 0;
      border-radius: 3px;
      background: transparent;
      cursor: pointer;
      vertical-align: middle;
    }
  }

  tbody {
    a {
      color: ${primary.blue()};
    }

    td:first-child {
      width: 60%;
    }

    span {
      position: relative;
      display: inline-block;
      min-height: 1em;
      overflow: hidden;

      &:empty {
        width: 60%;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${primary.gray(100)};
        pointer-events: none;
        opacity: 0;
        transition: .3s .1s;
        content: '';
      }
    }

    [data-testid="template-owner"] span:empty {
      width: 20%;
    }

    &.loading span,
    span:empty {
      &:after {
        pointer-events: auto;
        opacity: 1;
      }
    }

    [data-testid="templates-empty-message"] {
      text-align: center;
    }
  }

  tfoot td {
    > div {
      display: flex;
      justify-content: end;
    }
  }
`;
