export function sortByHelper(items, sortBy, sortOrder) {
  return items.sort((firstItem, secondItem) => {
    if (firstItem[sortBy] < secondItem[sortBy]) {
      return sortOrder === 'ASC' ? -1 : 1;
    }
    if (firstItem[sortBy] > secondItem[sortBy]) {
      return sortOrder === 'ASC' ? 1 : -1;
    }
    return 0;
  });
}
