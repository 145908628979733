export const templateSettingsService = (settings, id, checked) => {
  settings[id].checked = checked;

  if (id === 'changeStageSettings') {
    settings.changeStageDeadlines.checked = checked;
    settings.changeStageDeadlines.disabled = checked;
  }

  return settings;
};
