import React from 'react';
import { Link } from 'react-router-dom'

export const TemplatesListEntryItem = ({ id, name, owner }) => {
  const url = `/setup/approvals/proof/${id}`;
  
  return (
    <tr data-testid="templates-item">
      <td data-testid="templates-name">
        <span>{name && <Link to={url}>{name}</Link>}</span>
      </td>
      <td data-testid="templates-owner">
        <span>{owner}</span>
      </td>
    </tr>
  );
};
