import React from 'react';
import { useSelector } from 'react-redux';
import { Localization } from '@wf-mfe/localize-react';
import { Button, Loader, primary } from '@phoenix/all';
import { templateController } from '../controllers/';

export const TemplateFooter = () => {
  const { editMode, updating } = useSelector(state => state.workflow);
  const disabled = updating || !editMode;
  
  const pageFooterStyles = `
    position: sticky;
    top: calc(100% - 70px);
    height: 70px;
    width: 100%;
    padding: 20px 26px 26px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 3px 0px;
    background: ${primary.white()};
    transition: transform .25s .25s;
    z-index: 10000;
    transform: translateY(${editMode ? 0 : '150%'});

    [data-testid="workflow-template-loader"] {
      margin: -24px -26px 20px;
      transition: opacity .25s;
      opacity: ${updating ? 1 : 0};
    }
  `;

  return (
    <div css={pageFooterStyles} data-testid="workflow-template-footer">
      <Loader infinite paused={!updating} testId="workflow-template-loader" />
      <Button
        disabled={disabled}
        onClick={() => templateController.updateTemplate()}
        testID="workflow-save"
      >
        <Localization messageKey="proof.action.savechanges" fallback="Save changes" />
      </Button>
      <Button
        disabled={disabled}
        onClick={() => templateController.cancelEditTemplate()}
        testID="workflow-cancel"
        text
      >
        <Localization messageKey="proof.action.cancel" fallback="Cancel" />
      </Button>
    </div>
  );
};
