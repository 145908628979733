import { sortByHelper } from '../helpers/';
import { fixture } from '../stores/TemplateListFixture';

export const restAPI = {
  fetchTemplate: async (id) => {
    return Promise.resolve(id);
  },

  fetchTemplates: async (page, sortBy, sortOrder) => {
    const multiplier = 10;
    const start = (page - 1) * multiplier;
    const end = start + multiplier;
    const items = sortByHelper(fixture, sortBy, sortOrder);

    return Promise.resolve({
      itemsPerPage: multiplier,
      itemsTotal: fixture.length,
      currentPage: page,
      results: items.slice(start, end),
      sortBy,
      sortOrder,
      total_pages: Math.ceil(fixture.length / multiplier),
    });
  },

  updateTemplate: async () => {
    return Promise.resolve();
  },
}
