import { createSlice } from '@reduxjs/toolkit';
import { templateInitialState } from './templateInitialState';
import { templateSettingsService } from '../../services/templateSettingsService';

export const TemplateSlice = createSlice({
  name: 'template',
  initialState: {
    template: templateInitialState,
    edit: templateInitialState,
    updating: false,
    updated: null,
    loading: false,
    editMode: false,
    settingsEditMode: false,
  },
  reducers: {
    flushTemplate: state => {
      state.updated = null;
      state.template = templateInitialState;
      state.edit = templateInitialState;
    },
    templateFetched: (state, { payload }) => {
      state.template.id = payload.id;
      state.edit = state.template;
    },
    templateUpdated: state => {
      state.updated = Date.now();
    },
    toggleEditMode: (state, { payload }) => {
      state.editMode = payload;
    },
    toggleUpdateMode: (state, payload) => {
      state.updating = payload;
    },
    openTemplateSettings: state => {
      state.settingsEditMode = true;
    },
    applyTemplateSettings: state => {
      state.settingsEditMode = false;
    },
    cancelTemplateSettings: state => {
      state.settingsEditMode = false;
      state.edit.settings = state.template.settings;
    },
    changeTemplateSettings: (state, { payload }) => {
      state.edit.settings = templateSettingsService(state.edit.settings, payload.id, payload.checked);
    }
  }
});
