import { restAPI } from '../lib/restAPI';
import { router } from '../routers/router';
import { TemplateSlice } from '../stores/slices/TemplateSlice';
import { TemplateStore } from '../stores/TemplateStore';

export const templateController = {
  router,

  api: restAPI,

  actions: TemplateSlice.actions,

  dispatch: TemplateStore.dispatch,

  emit(reducerFxName) {
    this.dispatch({ type: `template/${reducerFxName}` });
  },

  getState() {
    return TemplateStore.getState().workflow;
  },

  async fetchTemplate(id) {
    await this.api.fetchTemplate();

    setTimeout(() => {
      this.dispatch(this.actions.templateFetched({ id }));
    }, 1000);
  },

  async updateTemplate() {
    this.dispatch(this.actions.toggleUpdateMode(true));
    await this.api.updateTemplate();

    setTimeout(() => {
      this.templateUpdated();
    }, 1500);
  },

  templateUpdated() {
    this.dispatch(this.actions.toggleUpdateMode(false));
    this.pushUpdatedSuccessNotification();
    this.leaveEditTemplate();
  },

  pushUpdatedSuccessNotification() {
    this.dispatch(this.actions.templateUpdated());
  },

  cancelEditTemplate() {
    this.leaveEditTemplate();
  },

  leaveEditTemplate() {
    const { template } = this.getState();
    this.dispatch(this.actions.toggleEditMode(false));
    this.router.changeToView(template.id);
  },

  enterEditPage() {
    const { editMode, template } = this.getState();
    if (!editMode) {
      this.router.goToEdit(template.id);
    }
  },

  toggleEditMode(isEditPage) {
    this.dispatch(this.actions.toggleEditMode(!!isEditPage));
  },

  changeTemplateSetting(id, checked) {
    this.enterEditPage();
    this.dispatch(this.actions.changeTemplateSettings({ id, checked }));
  }
};
