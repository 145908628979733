import React from 'react';
import { Button, primary } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import SettingsIcon from 'phoenix-icons/dist/SettingsIcon.js';
import { templateController } from '../../controllers/';

export const TemplateSettingsButton = () => {
  return (
    <Button
      css={`
        padding: 0 4px;
        color: ${primary.gray()};
        font-weight: 500;

        &:hover:enabled {
          color: ${primary.gray()};
          background-color: ${primary.gray(100)};
          text-decoration: none;
        }
      `}
      data-testid="create-workflow-template"
      onClick={() => templateController.emit('openTemplateSettings')}
      text
    >
      <span
        css={`
          align-self: center;
          height: 24px;
          line-height: 100%;
          margin-right: 4px;
          vertical-align: middle;
          width: 24px;
        `}
      >
        <SettingsIcon />
      </span>
      <Localization messageKey="proof.settings" fallback="Settings" />
    </Button>
  );
}
