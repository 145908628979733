export const placeholder = [
  { id: 1, name: '', owner: '' },
  { id: 2, name: '', owner: '' },
  { id: 3, name: '', owner: '' },
  { id: 4, name: '', owner: '' },
  { id: 5, name: '', owner: '' },
  { id: 6, name: '', owner: '' },
  { id: 7, name: '', owner: '' },
  { id: 8, name: '', owner: '' },
  { id: 9, name: '', owner: '' },
  { id: 10, name: '', owner: '' },
];

export const fixture = [
  { id: 1, owner: 'Michalina Nixon', name: '10 Awesome Ways to Photograph Hamsters' },
  { id: 2, owner: 'Ava-Mae Howe', name: "7 Pictures of Bruce Springsteen That We'd Rather Forget" },
  { id: 3, owner: 'Alexandra Macleod', name: 'How to Increase Your Income Using Just Your Arm Pits.' },
  { id: 4, owner: 'Maximillian Hirst', name: '21 Myths About Hamsters Debunked' },
  { id: 5, owner: 'Monique Ross', name: 'Introducing Anonymous - Who Am I And Why Should You Follow Me' },
  { id: 6, owner: 'Carlie Lister', name: 'Shape Shifter : Fact versus Fiction' },
  { id: 7, owner: 'Jokubas Khan', name: 'Can Hamsters Dance : An exploration of Memes' },
  { id: 8, owner: 'Ashlee Hastings', name: 'Hamsters Are the New Black' },
  { id: 9, owner: 'Michalina Nixon', name: '20 Skirt Reviews in Tweet Form' },
  { id: 10, owner: 'Ava-Mae Howe', name: 'From Zero to Shape Shifter - Makeover Tips' },
  { id: 11, owner: 'Alexandra Macleod', name: 'How to Make Your Own Admirable Skirt for less than £5' },
  { id: 12, owner: 'Jokubas Khan', name: "Dale Cooper's 10 Best Moments" },
  { id: 13, owner: 'Ashlee Hastings', name: 'How to Attract More Admirable Subscribers' },
  { id: 14, owner: 'Michalina Nixon', name: 'A Day in the Life of Anonymous' },
  { id: 15, owner: 'Ava-Mae Howe', name: 'Unboxing My New Shape Shifter Poo' },
  { id: 16, owner: 'Alexandra Macleod', name: "The Week's Top Stories About Bruce Springsteen" },
  { id: 17, owner: 'Maximillian Hirst', name: "10 Things You've Always Wanted to Know About the Famous Shape Shifter" },
  { id: 18, owner: 'Monique Ross', name: '7 Unmissable YouTube Channels About Drums' },
  { id: 19, owner: 'Carlie Lister', name: '10 Things Dale Cooper Can Teach Us About Drums' },
  { id: 20, owner: 'Jokubas Khan', name: 'Mistakes That Hamsters Make and How to Avoid Them' },
  { id: 21, owner: 'Ashlee Hastings', name: 'Like Epsilon Eridani on Earth' },
  { id: 22, owner: 'Neave King', name: 'Before Modest Drums' },
  { id: 23, owner: 'Isabelle Madden', name: 'Down With the Drums' },
  { id: 24, owner: 'Michalina Nixon', name: 'Modest, Admirable, Grateful' },
  { id: 25, owner: 'Ava-Mae Howe', name: 'Shape Shifter Memories' },
  { id: 26, owner: 'Monique Ross', name: 'Drums Dreams' },
  { id: 27, owner: 'Ashlee Hastings', name: 'Mind Over Drums' },
  { id: 28, owner: 'Neave King', name: 'The Modest Side of the Knight' },
  { id: 29, owner: 'Isabelle Madden', name: 'Born to Chase Hamsters' },
  { id: 30, owner: 'Michalina Nixon', name: 'Tunnel of Drums' },
  { id: 31, owner: 'Ava-Mae Howe', name: 'See also Song Name Generator' },
  { id: 32, owner: 'Monique Ross', name: 'Bridge Over Admirable Hamsters' },
  { id: 33, owner: 'Ashlee Hastings', name: 'Truly, Madly, Admirable' },
  { id: 34, owner: 'Neave King', name: 'Bruce Springsteen Dreams' },
  { id: 35, owner: 'Isabelle Madden', name: 'Knight Boogie Woogie' },
  { id: 36, owner: 'Michalina Nixon', name: 'Great Drums of Knight' },
  { id: 37, owner: 'Ava-Mae Howe', name: 'Knightlife' },
  { id: 38, owner: 'Monique Ross', name: "If You're Feeling Grateful" },
  { id: 39, owner: 'Carlie Lister', name: 'Modest Knight Etiquette' },
  { id: 40, owner: 'Alexandra Macleod', name: 'The Knight' },
  { id: 41, owner: 'Neave King', name: 'Captain Shape Shifter and the Drums' },
  { id: 42, owner: 'Maximillian Hirst', name: 'Modest hamsters cause tidal waves. Discuss.' },
  { id: 43, owner: 'Isabelle Madden', name: 'What role do Canadian hamsters play in the food chain?' },
  { id: 44, owner: 'Jokubas Khan', name: "Deciding boundaries : Hamsters' arm pits and priviledge." },
  { id: 45, owner: 'Ashlee Hastings', name: 'Is there life on Epsilon Eridani? Discuss with reference to nipples.' },
  { id: 46, owner: 'Ava-Mae Howe', name: 'Myths and Legends : The role of the humble shape shifter in shaping superstition.' },
  { id: 47, owner: 'Alexandra Macleod', name: 'Dale Cooper : Is there value in analysing the impact of fictional characters on tidal waves?' },
  { id: 48, owner: 'Maximillian Hirst', name: 'What can the patriarchy teach us about shape shifter?' },
  { id: 49, owner: 'Monique Ross', name: 'An analysis of grateful drums' },
  { id: 50, owner: 'Carlie Lister', name: 'The Canadian Have a Unique Culture. Discuss.' },
  { id: 51, owner: 'Jokubas Khan', name: 'An brief history of admirable drums' },
  { id: 52, owner: 'Ashlee Hastings', name: 'See also Fairytale Generator' },
  { id: 53, owner: 'Michalina Nixon', name: 'A Flock of Modest Hamsters' },
  { id: 54, owner: 'Ava-Mae Howe', name: 'The Admirable Shape Shifter' },
  { id: 55, owner: 'Alexandra Macleod', name: 'Why Are My Arm Pits Growing?' },
  { id: 56, owner: 'Jokubas Khan', name: 'Epsilon Eridani and Other Great Places to Take Your Spaceship' },
  { id: 57, owner: 'Ashlee Hastings', name: 'Hamsters Versus Students - The Final Battle' },
  { id: 58, owner: 'Michalina Nixon', name: 'Anonymous Saves the Day' },
  { id: 59, owner: 'Ava-Mae Howe', name: 'The Big, Ginger Knight' },
  { id: 60, owner: 'Alexandra Macleod', name: 'The Amazing Adventures of Anonymous' },
  { id: 61, owner: 'Maximillian Hirst', name: 'King of Grateful Drums' },
  { id: 62, owner: 'Monique Ross', name: 'Enemy of the Modest Knight' },
  { id: 63, owner: 'Carlie Lister', name: 'Bruce Springsteen Spotted Wearing Ginger Skirt Again' },
  { id: 64, owner: 'Jokubas Khan', name: 'Dale Cooper To Appear In The New Star Wars Film' },
  { id: 65, owner: 'Ashlee Hastings', name: 'Epsilon Eridani to be Visible From Earth Tonight at 1am' },
  { id: 66, owner: 'Neave King', name: 'Innapropriate Skirt Causes Tidal Waves, Say Experts' },
  { id: 67, owner: 'Isabelle Madden', name: 'Controversy as Big Knight Embroilled in Health Scare' },
  { id: 68, owner: 'Michalina Nixon', name: 'Stroking Hamsters Prevents Serious Illness, Says Expert' },
  { id: 69, owner: 'Ava-Mae Howe', name: 'Shares in Drums at All Time High' },
  { id: 70, owner: 'Monique Ross', name: 'A Knight Brings Down Drums' },
  { id: 71, owner: 'Ashlee Hastings', name: 'The Mystery Behind Drums Finally Revealed' },
  { id: 72, owner: 'Neave King', name: "You'll Never Fear Modest Hamsters Again After Reading This" },
];
