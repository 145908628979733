import React from 'react';
import { Checkbox } from '@phoenix/all';
import { useSelector } from 'react-redux';
import { templateController } from '../../controllers/';

export const TemplateSettingsOption = ({ id, label }) => {
  const { checked, disabled } = useSelector(state => state.workflow.edit.settings[id]);

  return (
    <li>
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={({ target }) => templateController.changeTemplateSetting(id, target.checked)}
        label={label}
      />
    </li>
  );
};