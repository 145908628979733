export const templateSettingsStyles = `
  h4 {
    margin: 0 0 20px;
    font-size: 1.25rem;
    font-weight: 500;
  }

  h5 {
    margin: 0 0 10px;
  }

  ul {
    display: inline-flex;
    width: 50%;
    flex-direction: column;
  }

  li {
    margin: 3px 0;
  }
`;
