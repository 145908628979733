import React from 'react';
import { useSelector } from 'react-redux';
import { TableHead } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import { TemplatesListHeaderColumn } from './TemplatesListHeaderColumn';

export const TemplatesListHeader = () => {
  const sortBy = useSelector(state => state.templates.sortBy);

  return (
    <TableHead>
      <tr>
        <TemplatesListHeaderColumn
          title={<Localization messageKey="proof.name" fallback="Name" />}
          sortBy="name"
          isActive={sortBy === 'name'}
        />
        <TemplatesListHeaderColumn
          title={<Localization messageKey="proof.owner" fallback="Owner" />}
          sortBy="owner"
          isActive={sortBy === 'owner'}
        />
      </tr>
    </TableHead>
  );
};
