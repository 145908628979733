import { useEffect } from 'react';
import { toast } from '@phoenix/all';
import { useSelector } from 'react-redux';
import { useLocalization } from '@wf-mfe/localize-react';

export const TemplateNotifications = () => {
  const { updated } = useSelector(state => state.workflow);
  const [statusUpdated] = useLocalization('proof.workflow.updated', 'Template was updated');

  useEffect(() => {
    if (updated) {
      toast.success(statusUpdated);
    }
  }, [updated, statusUpdated]);

  return null;
}
