import React from 'react';
import { LocalizationProvider } from '@workfront/localize-react';
import { Switch, Route } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import { NewTemplate } from '../pages/NewTemplate/NewTemplate';
import { TemplateListPage } from '../pages/TemplateListPage';
import { TemplatePage } from '../pages/TemplatePage';
import { NotFound } from '../pages/NotFound/NotFound';
import { routes } from './routes';

const clientFactory = () => ({
  namespace: 'proof'
});

const AppRouter = () => (
  <ErrorBoundary>
    <LocalizationProvider clientFactory={clientFactory}>
      <Switch>
        <Route exact path={routes.list} component={TemplateListPage} />
        <Route exact path={routes.new} component={NewTemplate} />
        <Route exact path={routes.view} component={TemplatePage} />
        <Route exact path={routes.edit} component={TemplatePage} />
        <Route component={NotFound} />
      </Switch>
    </LocalizationProvider>
  </ErrorBoundary>
);

export default AppRouter;
