import React from 'react';
import { useSelector } from 'react-redux';
import { Localization } from '@workfront/localize-react';

export const TemplatesListEmptyMessage = () => {
  const hasEmptyList = !useSelector(state => state.templates.list.length);
  const hasModifiedList = !useSelector(state => state.templates.pristine);
  const displayMessage = hasModifiedList && hasEmptyList;

  return (
    displayMessage && (
      <tr>
        <td colSpan={2} data-testid="templates-empty-message">
          <Localization
            messageKey="proof.workflow.templates.empty.list"
            fallback="You don't have any Proof Workflow Templates"
          />
        </td>
      </tr>
    )
  );
};
