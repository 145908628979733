import { restAPI } from '../lib/restAPI';
import { TemplatesListStore } from '../stores/TemplatesListStore';
import { TemplatesListSlice } from '../stores/slices/TemplatesListSlice';

export const templatesListController = {
  api: restAPI,

  actions: TemplatesListSlice.actions,

  dispatch: TemplatesListStore.dispatch,

  getState() {
    return TemplatesListStore.getState().templates;
  },

  sortColumn(sortBy, sortOrder) {
    const state = this.getState();
    this.fetchTemplates(state.currentPageNumber, sortBy, sortOrder);
  },

  changePage(pageNumber) {
    const state = this.getState();
    this.fetchTemplates(pageNumber, state.sortBy, state.sortOrder);
  },

  async fetchTemplates(pageNumber, sortBy, sortOrder) {
    this.dispatch(this.actions.startLoading());
    const response = await this.api.fetchTemplates(
      pageNumber,
      sortBy,
      sortOrder
    );

    setTimeout(() => {
      this.displayTemplates(response);
    }, 1000);
  },

  displayTemplates(response) {
    this.dispatch(this.actions.updateTemplates(response));
    this.dispatch(this.actions.stopLoading());
  }
};
