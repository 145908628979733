import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ArrowsFilterIcon from 'phoenix-icons/dist/ArrowsFilterIcon.js';
import ArrowsFilterAscendingIcon from 'phoenix-icons/dist/ArrowsFilterAscendingIcon.js';
import ArrowsFilterDescendingIcon from 'phoenix-icons/dist/ArrowsFilterDescendingIcon.js';
import { templatesListController } from '../../controllers/';

export const TemplatesListHeaderColumn = ({ title, isActive, sortBy }) => {
  const sortOrder = useSelector(state => state.templates.sortOrder);

  const isASC = sortOrder === 'ASC';
  const newSortOrder = isASC ? 'DESC' : 'ASC';

  let Icon = null;
  let testID = `templates-sort-button-${sortBy}-`;

  if (isActive) {
    Icon = isASC ? ArrowsFilterAscendingIcon : ArrowsFilterDescendingIcon;
    testID += sortOrder;
  } else {
    Icon = ArrowsFilterIcon;
    testID += 'none';
  }

  return (
    <th data-testid={`templates-column-${sortBy}`}>
      {title}
      <button
        type="button"
        onClick={() => templatesListController.sortColumn(sortBy, newSortOrder)}
        data-testid={testID}
      >
        <Icon width={16} height={16} />
      </button>
    </th>
  );
};

TemplatesListHeaderColumn.propTypes = {
  isActive: PropTypes.bool,
  sortBy: PropTypes.string,
  title: PropTypes.node,
};
