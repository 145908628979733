import React from 'react';
import { ConfirmDialog } from '@phoenix/all';
import { Localization } from '@wf-mfe/localize-react';
import { useSelector } from 'react-redux';
import { templateController } from '../../controllers/';
import { TemplateSettingsOption } from './TemplateSettingsOption';
import { templateSettingsStyles } from './templateSettingsStyles';

export const TemplateSettings = () => {
  const { settingsEditMode } = useSelector(state => state.workflow);
  
  return settingsEditMode && (
    <ConfirmDialog
      medium
      confirmText={<Localization messageKey="proof.action.done" fallback="Done" />}
      denyText={<Localization messageKey="proof.action.cancel" fallback="Cancel" />}
      onConfirmClick={() => templateController.emit('applyTemplateSettings')}
      onDenyClick={() => templateController.emit('cancelTemplateSettings')}
    > 
      <div css={templateSettingsStyles}>
        <h4>
          <Localization messageKey="proof.settings" fallback="Settings" />
        </h4>
        <h5>
          <Localization messageKey="proof.workflow.allow.owner" fallback="Allow proof owner to" />
        </h5>
        <ul>
          <TemplateSettingsOption 
            id="addStages"
            label={<Localization messageKey="proof.workflow.add.stages" fallback="Add stages" />}
          />
          <TemplateSettingsOption 
            id="changeStageNames"
            label={<Localization messageKey="proof.workflow.change.stage.name" fallback="Change stage names" />}
          />
          <TemplateSettingsOption 
            id="changeStageDeadlines"
            label={<Localization messageKey="proof.workflow.change.stage.deadline" fallback="Change stage deadlines" />}
          />
          <TemplateSettingsOption 
            id="changeStageSettings"
            label={<Localization messageKey="proof.workflow.change.stage.settings" fallback="Change stage settings" />}
          />
        </ul>
        <ul>
          <TemplateSettingsOption 
            id="addStagePeople"
            label={<Localization messageKey="proof.workflow.add.people" fallback="Add people to stages" />}
          />
          <TemplateSettingsOption 
            id="removeStagePeople"
            label={<Localization messageKey="proof.workflow.remove.people" fallback="Remove people from stages" />}
          />
          <TemplateSettingsOption 
            id="editStagePeople"
            label={<Localization messageKey="proof.workflow.edit.people" fallback="Edit people on stages" />}
          />
        </ul>
      </div>
    </ConfirmDialog>
  );
};
