import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createReactLifecycles } from 'single-spa-workfront';
import AppRouter from './routers/app-router';

export { TemplateListPage } from './pages/TemplateListPage';

export function ProofWorkflowTemplatesApp() {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
}


const lifecycles = createReactLifecycles({
  rootComponent: ProofWorkflowTemplatesApp,
});

export const { bootstrap } = lifecycles;
export const { mount } = lifecycles;
export const { unmount } = lifecycles;
