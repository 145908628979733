import React from 'react';
import { CreateTemplateButton } from '../components/CreateTemplateButton';
import { TemplatesList } from '../components/TemplatesList/';

export function TemplateListPage() {
  return (
    <div data-testid="template-list-page">
      <CreateTemplateButton />
      <TemplatesList />
    </div>
  );
}
