import { createSlice } from '@reduxjs/toolkit';
import { placeholder } from '../TemplateListFixture';

export const TemplatesListSlice = createSlice({
  name: 'templates',
  initialState: {
    list: placeholder,
    itemsCount: 0,
    itemsPerPage: 10,
    currentPageNumber: 1,
    sortOrder: 'ASC',
    sortBy: 'name',
    pristine: true,
    loading: true,
  },
  reducers: {
    startLoading: state => {
      state.loading = true;
    },
    stopLoading: state => {
      state.loading = false;
    },
    updateTemplates: (state, { payload }) => {
      state.currentPageNumber = payload.currentPage;
      state.itemsCount = payload.itemsTotal;
      state.itemsPerPage = payload.itemsPerPage;
      state.list = payload.results;
      state.pristine = false;
      state.sortBy = payload.sortBy;
      state.sortOrder = payload.sortOrder;
    }
  }
});
