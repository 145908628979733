export const templateInitialState = {
  id: null,
  settings: {
    addStages: { checked: false, disabled: false },
    changeStageNames: { checked: false, disabled: false },
    changeStageDeadlines: { checked: false, disabled: false },
    changeStageSettings: { checked: false, disabled: false },
    addStagePeople: { checked: false, disabled: false },
    removeStagePeople: { checked: false, disabled: false },
    editStagePeople: { checked: false, disabled: false },
  },
};
