import React from 'react';
import { css } from 'emotion';
import { Button } from '@phoenix/all';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import { history } from'@wf-mfe/navigation';
import { Localization } from '@wf-mfe/localize-react';
import AddIcon from 'phoenix-icons/dist/AddIcon.js';

export function CreateTemplateButton() {
  const createWorkflowTemplate = useIsTreatmentEnabled(
    'create-proof-workflow-template-nwe'
  );

  const createTemplateButtonIconStyles = css(`
      align-self: center;
      height: 24px;
      line-height: 100%;
      margin-right: 4px;
      vertical-align: middle;
      width: 24px;
    `);

  const createTemplateButtonWrapperStyles = css(`
      min-height: 40px;
    `);

  if (!createWorkflowTemplate) {
    return null;
  }

  return (
    <div className={createTemplateButtonWrapperStyles}>
      <Button
        data-testid="create-workflow-template"
        onClick={() => history.push('/setup/approvals/proof/new')}
        text
      >
        <span className={createTemplateButtonIconStyles}>
          <AddIcon />
        </span>
        <Localization
          messageKey="proof.workflow.template.new"
          fallback="New Template"
        />
      </Button>
    </div>
  );
}
