import React from 'react';
import { css } from '@emotion/css';

export const pageStyle = css`
  background-color: #9bd4bc;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
`;

export function NewTemplate() {
  return (
    <div className={pageStyle} data-testid="new-template-page">
      <h1>New Template</h1>
    </div>
  );
}
