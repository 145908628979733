import React from 'react';
import { TableBody } from '@phoenix/all';
import { useSelector } from 'react-redux';
import { TemplatesListEmptyMessage } from './TemplatesListEmptyMessage';
import { TemplatesListEntryItem } from './TemplatesListEntryItem';

export const TemplatesListEntries = () => {
  const loading = useSelector(state => state.templates.loading);
  const list = useSelector(state => state.templates.list);
  const className = [loading ? 'loading' : ''].join(' ');

  return (
    <TableBody className={className}>
      <TemplatesListEmptyMessage />
      {list.map(item => <TemplatesListEntryItem  key={item.id} {...item} />)}
    </TableBody>
  );
};
