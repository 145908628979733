import React from 'react';
import { primary } from '@phoenix/all';
import { TemplateSettingsButton } from './TemplateSettingsButton';

export const TemplateToolbar = () => {
  return (
    <div
      css={`
        display: flex;
        flex-grow: 1;
        height: 41px;
        clear: both;
        align-items: center;
        border-top: 1px solid ${primary.gray(200)};
        border-bottom: 1px solid ${primary.gray(200)};
      `}
    >
      <div
        id="toolbar-items"
        css={`
          display: flex;
          flex: 1;
          width: 100%;
          height: 32px;
          padding: 0 24px;
          justify-content: space-between;
        `}
      >
        <div id="toolbar-left"></div>
        <div id="toolbar-right">
          <TemplateSettingsButton />
        </div>
      </div>
    </div>
  );
}