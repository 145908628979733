import React, { useEffect } from 'react';
import { Provider } from 'react-redux'
import { Table } from '@phoenix/all';
import { TemplatesListStore } from '../../stores/TemplatesListStore'
import { templatesListController } from '../../controllers/';

import { TemplatesListHeader } from './TemplatesListHeader';
import { TemplatesListEntries } from './TemplatesListEntries';
import { TemplatesListFooter } from './TemplatesListFooter';
import { TemplatesListStyles } from './TemplatesListStyles';

export const TemplatesList = () => {
  useEffect(() => {
    const defaultPageNumber = 1;
    templatesListController.changePage(defaultPageNumber);
  }, []);

  return (
    <Provider store={TemplatesListStore}>
      <div data-testid="proof-templates-list" css={TemplatesListStyles}>
        <Table>
          <TemplatesListHeader />
          <TemplatesListEntries />
          <TemplatesListFooter />
        </Table>
      </div>
    </Provider>
  );
};
