import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PageTitle } from '@phoenix/all';
import { TemplateStore } from '../stores/TemplateStore'
import { routes } from '../routers/routes'
import { TemplateToolbar } from '../components/TemplateToolbar/';
import { TemplateSettings } from '../components/TemplateSettings/';
import { TemplateFooter } from '../components/TemplateFooter';
import { templateController } from '../controllers/';
import { TemplateNotifications } from '../components/TemplateNotifications';

export function TemplatePage() {
  const { id } = useParams();
  const isEditPage = !!useRouteMatch(routes.edit);

  useEffect(() => {
    templateController.toggleEditMode(isEditPage);
  }, [isEditPage]);

  useEffect(() => {
    templateController.fetchTemplate(id);
    
    return () => {
      templateController.emit('flushTemplate');
    };
  }, [id]);

  return (
    <Provider store={TemplateStore}>
      <div
        data-testid="view-template-page"
        css={`
          min-height: 100%;
        `}
      >
        <div data-testid="workflow-template-header">
          <PageTitle
            objectLabel="Proof workflow template"
            titleText={`Workflow template: ${id}`}
          />
          <TemplateToolbar />
          <TemplateSettings />
        </div>
        <TemplateFooter />
        <TemplateNotifications />
      </div>
    </Provider>
  );
}
