import React from 'react';
import { useSelector } from 'react-redux';
import { TableFoot, Pagination } from '@phoenix/all';
import { templatesListController } from '../../controllers/';

export const TemplatesListFooter = () => {
  const state = useSelector(state => state.templates);

  return (
    <TableFoot>
      <tr>
        <td colSpan={2}>
          <div data-testid="proof-templates-pagination">
            <Pagination
              testID="proof-templates"
              setPage={pageNum => templatesListController.changePage(pageNum)}
              currentPage={state.currentPageNumber}
              itemsCount={state.itemsCount}
              itemsPerPage={state.itemsPerPage}
            />
          </div>
        </td>
      </tr>
    </TableFoot>
  );
};
