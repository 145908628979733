import React from 'react';
import { css } from '@emotion/css';

export const pageStyle = css`
  background-color: #9bd4bc;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export function NotFound() {
  return <div className={pageStyle} data-testid="not-found-page">Page not found</div>;
}
